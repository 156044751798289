import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../shared/service/authentication.service';

@Component({
  selector: 'ucs-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: false
})
export class LandingPageComponent implements OnInit {

  saveSelection: boolean = false;
  authProvider: string;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.authProvider = JSON.parse(localStorage.getItem('ucs-auth-provider'));
    if (this.authProvider) {
      this.saveSelection = true;
    }
  }

  login(target: string) {
    this.authenticationService.login(target);
    if (this.saveSelection) {
      localStorage.setItem('ucs-auth-provider', JSON.stringify(target));
    }
  }

  toggleSaveSelection() {
    this.saveSelection = !this.saveSelection;
    if (!this.saveSelection) {
      this.authProvider = undefined;
      localStorage.removeItem('ucs-auth-provider');
    }
  }
}
